import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  showConfirmAlert(title: string, text: string, confirmButtonText?: string): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonColor:"#FF0000",
      confirmButtonText: confirmButtonText || 'Confirm',
    });
  }
  showConfirmAlertWithInput(title: string, text: string, confirmButtonText?: string): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      icon:'warning',
      input:'textarea',
      inputPlaceholder: 'Enter your reason for rejection...',
      showCancelButton: true,
      confirmButtonText: confirmButtonText || 'Confirm',
      cancelButtonText:'Cancel',
      cancelButtonColor: "#3085d6",
      confirmButtonColor:"#FF0000",
      preConfirm:(inputValue) => {
        return inputValue;
      },
      willOpen: () => {
        const input = Swal.getInput();
        const confirmButton = Swal.getConfirmButton();
        if (input && confirmButton) {
          confirmButton.disabled = true;
          input.addEventListener('input', () => {
            confirmButton.disabled = !input.value; 
          });
        }
      }
    });
  }
  showErrorAlert(title: string, text?: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }
  showSuccessAlert(title: string, text?: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
    });
  }
  constructor() { }
  showDeleteAlert() {
    const result = Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Delete"
    });
    return result;
  }
  showDeleteSuccess(){
    const successDelete = Swal.fire({
              title: "Deleted!",
               text: "Deleted Successfully",
               icon: "success"
             });
             return successDelete
  }

}
