import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { ChangePasswordComponent } from 'src/app/pages/auth/change-password/change-password.component';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { cloneDeep } from 'lodash-es';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<boolean>();

  isExpanded = true;
  showDropdown = false;
  isChecked?: boolean;
  userData?: any;
  partnerType?: any;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade: AppStoreFacade,
    private _cdr: ChangeDetectorRef
  ) {
    this._appStoreFacade.user$.pipe()
      .subscribe((data: any) => {
        this.userData = data;
        this._cdr.markForCheck();
      })
    this.partnerType = localStorage.getItem('partnerType');
  }
  ngOnInit(): void {
    if (this.userData?.store_status === 'Online') {
      this.isChecked = true
    }
    else {
      this.isChecked = false
    }
    this._cdr.markForCheck();
  }
  onToggleChange(event: boolean) {
    if (event === true) {
      this._authService.changeStatus(this._authService.getUserId(), 'Online').subscribe(
        res => {
          if (res.success == true) {
            const userData = this.userData ?? {};
            const updatedUserData = { ...userData, store_status: 'Online' };
            this._appStoreFacade.updateUser(updatedUserData);
            this._snackbarService.showSuccess('You are Online Now!!!');
          }
          else {
            this._snackbarService.showError(res.message)
          }
        })
    }
    else {
      this._authService.changeStatus(this._authService.getUserId(), 'Offline').subscribe(
        res => {
          if (res.success == true) {
            const userData = this.userData ?? {};
            const updatedUserData = { ...userData, store_status: 'Offline' };
            this._appStoreFacade.updateUser(updatedUserData);
            this._snackbarService.showSuccess('You are Offline Now!!!');
          }
          else {
            this._snackbarService.showError(res.message)
          }
        })
    }
  }
  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
    this.toggleSidenav.emit(this.isExpanded); // Emit the current expanded state
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event?.target as HTMLElement;
    const isProfileIcon = !!target.closest('.imageProfile');
    if (!isProfileIcon) {
      this.showDropdown = false;
    }
  }
  profile() {
    this.showDropdown = !this.showDropdown
  }
  changePassword() {
    this._router.navigate(['/auth/change-password']);
  }
  logout() {
    this._authService.clearAuthToken();
    this._router.navigate(['/auth']);
  }
  navigateTo(type: string) {
    if (type === 'myProfile') {
      this._router.navigate(['/my-account/update-profile']);
    }
    else if (type === 'businessHours') {
      this._router.navigate(['/my-account/set-business-hours']);
    }
  }
}
