import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DeliveryExecutiveService implements OnInit{
  // apiUrl = "http://pms-api.stg.fashquik.com";
  apiUrl = environment.API_URL;
  endpointsForDeliveryExecutive = {
    get: "/deliveryExecutive",
    getPersonalInfo: "/deliveryExecutive/personal-information",
    getBankinginfo: "/deliveryExecutive/banking-information",
    getEmploymentInfo:"/deliveryExecutive/employment-details",
    getTrainingAcknowledgement:"/deliveryExecutive/training-acknowledgment",
    uploadImage:"/upload"
  }
  constructor(private http: HttpClient,private _executiveService :AuthService) { }
  
  getdeliveryExecutive(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}?isDeleted=false`, { 
      headers: this._executiveService.getHeaders()
     });
  }
  getExecutiveById(id:any): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}/${id}`, { 
      headers: this._executiveService.getHeaders()
      
     });
  }
  deleteDeliveryExecutive(id: string):Observable<any> {
    
    return this.http.delete<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}/${id}` ,{ 
      headers: this._executiveService.getHeaders()
     });
  }
  addDeliveryExecutive(data:any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}`,data ,{ 
      headers: this._executiveService.getHeaders()
     });
  }
 
  updatePersonalInformation(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getPersonalInfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateEmploymentDetails(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getEmploymentInfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateBankingInformation(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getBankinginfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateTrainingAcknowledgement(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getTrainingAcknowledgement}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  uploadImg(data:any){
    return this.http.post(`${this.apiUrl}${this.endpointsForDeliveryExecutive.uploadImage}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  


  ngOnInit(): void {
  }
}
