import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppStoreFacade } from './+state/app-store.facade';
import * as DeviceDetector from 'device-detector-js';
import { DeviceDetectorResult } from 'device-detector-js';
import { Subject, delay, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'fak-pms';
  isLoading: boolean = false;

  device: DeviceDetectorResult | undefined;
  deviceDetector = new DeviceDetector();

  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private _appStoreFacade: AppStoreFacade,
    private _cdr: ChangeDetectorRef
  ){

    try {
      this.device = this.deviceDetector.parse(window.navigator.userAgent);
    } catch (error) {
      
    }

    this._appStoreFacade.isLoading$.pipe(takeUntil(this._unsubscribe$), delay(0))
    .subscribe((visibility: any) => {
      this.isLoading = visibility;
      this._cdr.markForCheck();
    });

    this._appStoreFacade.updateDeviceDetails(this.device);

  }

  ngOnInit(): void {
    
  }
}
