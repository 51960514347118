import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { AddExecutiveComponent } from './add-executive/add-executive.component';
import { UpdateExecutiveComponent } from './update-executive/update-executive.component';
import { DeliveryExecutiveService } from './delivery-executive.service'

const routes: Routes = [
  {path:'executive-list',component:ExecutiveListComponent},
  {path:'add-executive',component:AddExecutiveComponent},
  {path:'update-executive/:id',component:UpdateExecutiveComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryExecutiveRoutingModule { }
