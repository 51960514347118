import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeliveryExecutiveRoutingModule } from './delivery-executive-routing.module';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { AddExecutiveComponent } from './add-executive/add-executive.component';
import { UpdateExecutiveComponent } from './update-executive/update-executive.component';
import { LayoutModule } from 'src/app/shared/components/layout/layout.module';
import { MaterialModule } from 'src/app/shared/module/material-module/material-module.module';
import { DeliveryExecutiveStepOneComponent } from './update-executive/delivery-executive-step-one/delivery-executive-step-one.component';
import { DeliveryExecutiveStepTwoComponent } from './update-executive/delivery-executive-step-two/delivery-executive-step-two.component';
import { DeliveryExecutiveStepThreeComponent } from './update-executive/delivery-executive-step-three/delivery-executive-step-three.component';
import { DeliveryExecutiveStepFourComponent } from './update-executive/delivery-executive-step-four/delivery-executive-step-four.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ExecutiveListComponent,
    AddExecutiveComponent,
    UpdateExecutiveComponent,
    DeliveryExecutiveStepOneComponent,
    DeliveryExecutiveStepTwoComponent,
    DeliveryExecutiveStepThreeComponent,
    DeliveryExecutiveStepFourComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DeliveryExecutiveRoutingModule,
  ],
})
export class DeliveryExecutiveModule {}
