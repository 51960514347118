<app-layout>
    <div class="main-container">
        <div class="table-header-container d-flex justify-space-between">
          <h2 class="heading"> Executive  List</h2>
          <button mat-raised-button class="primary-button-color" style="padding: 1rem!important;"
            (click)="redirectActions('create')">Add Executive</button>
        </div>
        <div class="table-search-container">
            <div class="table-search-inner">
              <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search" class="search-input">

            </div>
          </div>
          <div class="table-main-container">
            <div class="table-inner-container">
              <table mat-table [dataSource]="dataSource">              
                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
                  <td mat-cell *matCellDef="let element; let i = index"> {{ calculateSerialNumber(i) }} </td>
                </ng-container>
        
                <ng-container matColumnDef="employeeId">
                  <th mat-header-cell *matHeaderCellDef class="small-heading">Employee ID </th>
                  <td mat-cell *matCellDef="let element"> {{element?.employmentDetails?.employeeId || '---'}} </td>
                </ng-container>
    
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef class="small-heading">Full Name </th>
                  <td mat-cell *matCellDef="let element"> {{element?.personalInformation.fullName}} </td>
                </ng-container>
    
                <ng-container matColumnDef="mobileNo">
                    <th mat-header-cell *matHeaderCellDef class="small-heading"> Mobile No. </th>
                    <td mat-cell *matCellDef="let element"> {{element?.personalInformation.phone_number}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef class="small-heading"> Email </th>
                  <td mat-cell *matCellDef="let element"> {{element?.personalInformation.email}} </td>
              </ng-container>

                <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Gender </th>
                    <td mat-cell *matCellDef="let element"> {{element?.personalInformation.gender}} </td>
                </ng-container>
    
    
                <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef class="small-heading"> State </th>
                  <td mat-cell *matCellDef="let element">  {{getStateName(element?.personalInformation?.state)}} </td>
                </ng-container>
    
                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef class="small-heading"> City </th>
                  <td mat-cell *matCellDef="let element"> {{getCityName(element?.personalInformation?.city)}} </td>
                </ng-container>
    
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="small-heading"> Action </th>
                  <td mat-cell *matCellDef="let element; let index = index" style="position: relative;"> 
                    <div class="action-icon-box">
                      <button class="mat-icon-button" matTooltip="View"><mat-icon class="custom-icon-view" >open_in_full</mat-icon></button>
                      <button class="mat-icon-button" matTooltip="Edit"><mat-icon class="custom-icon-edit" (click)="redirectActions('edit', element._id)">edit</mat-icon></button>
                      <button class="mat-icon-button" matTooltip="Delete"><mat-icon class="custom-icon-delete" (click)="deletedeliveryExecutive(element)">delete_outline</mat-icon></button>
                  </div>
                  </td>
                </ng-container>
        
                
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
              <mat-paginator [pageSizeOptions]="[10,25,50]" 
                             showFirstLastButtons
                             (page)="pageChanged($event)"
                             aria-label="Select page of periodic elements">
              </mat-paginator>
            </div>
          </div>
    </div>
</app-layout>
