import { ChangeDetectionStrategy, Component, Input, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {
  @Input() isSidenavExpanded: boolean = false;
  userRole: string | any;
  constructor(
    private _route: Router,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem('partnerType');
  }

  @ViewChild('sidenav') sidenav: any;

  category = false;
  brand = false;
  isSidenavOpened = true;
  partner = false;
  forms = false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isExpanded2 = true;
  showSubmenu2: boolean = false;
  isShowing2 = false;
  showSubSubMenu2: boolean = false;
  showSubmenu3:boolean =false;

  logout(): void {
    localStorage.removeItem('token');
    this._authService.userRole = this.userRole;
    this._route.navigate(['/']);
  }

  categoryCheckIn(): void {
    this.category = true;
  }

  brandCheckIn(): void {
    this.brand = true;
  }

  partnerCheckIn(): void {
    this.partner = true;
  }

  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  onSidenavToggle(isExpanded: boolean): void {
    this.sidenav.toggle();
    this.isSidenavOpened = !this.isSidenavOpened;
  }

  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  mouseenter2(): void {
    if (!this.isExpanded2) {
      this.isShowing2 = true;
    }
  }

  mouseleave2(): void {
    if (!this.isExpanded2) {
      this.isShowing2 = false;
    }
  }
}
