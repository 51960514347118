<mat-toolbar class="toolbar justify-space-between">
    <div fxLayout="row" class="d-flex align-items-center">
        <button mat-icon-button (click)="toggleExpansion()"><mat-icon>menu</mat-icon></button>
        <h1 class="app-name">PMS</h1>
    </div>

    <div class="imageProfile">
        <mat-slide-toggle *ngIf="this.partnerType === 'vendor'" class="VendorStatus" [(ngModel)]="isChecked"
            (ngModelChange)=" onToggleChange($event)"><span [ngClass]="{'online': isChecked, 'offline': !isChecked}">{{
                isChecked ? 'Online' : 'Offline'
                }}</span></mat-slide-toggle>
        <img (click)="profile()" src="./../../../../assets/Frame.svg">
    </div>
    <div class="dropDown">
        <mat-card *ngIf="showDropdown">
            <div fxLayout="column">
                <div class="dropDownData">
                    <button mat-flat-button (click)="navigateTo('myProfile')" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            <img src="./../../../../assets/person.svg">
                            <h4>My Profile</h4>
                        </div>
                    </button>
                </div>
                <div fxLayout="column" *ngIf="this.partnerType === 'vendor'">
                    <div class="dropDownData">
                        <button mat-flat-button (click)="navigateTo('businessHours')" fxLayout="row" fxLayoutAlign="start center">
                            <div fxLayout="row" fxLayoutGap="1rem">
                                <img src="./../../../../assets/time.svg">
                                <h4>Set Business Hours</h4>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="dropDownData">
                    <button mat-flat-button fxLayout="row" fxLayoutAlign="start center" (click)="changePassword()">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            <img src="./../../../../assets/lock_reset.svg">
                            <h4>Change Password</h4>
                        </div>
                    </button>
                </div>
                <div class="dropDownData">
                    <button mat-flat-button (click)="logout()" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            &nbsp;&nbsp;
                            <img src="./../../../../assets/logout.svg">
                            <h4>Logout</h4>
                        </div>
                    </button>
                </div>
            </div>
        </mat-card>
    </div>

</mat-toolbar>